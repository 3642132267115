import React from 'react';
import { responsiveStyles } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import Icon from './icon';
import { SocialMediaType } from '../../types/audi-footer-response.types';

interface SocialMediaProps {
  socialMedia: SocialMediaType[];
}

const FooterSocialMediaWrapperStyled = styled.div`
  display: flex;
  margin: 0 0 var(--one-footer-space-s);
  ${({ theme }): string =>
    responsiveStyles(
      {
        'justify-content': { m: 'flex-end' },
      },
      theme.breakpoints
    )}

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      display: inline-block;
      margin: 0 0 0 var(--one-footer-space-xs);

      & > a {
        display: inline-block;

        & > span {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
      }
    }

    & > li:first-child {
      margin: 0;
    }
  }
`;

const SocialMedia: React.FC<SocialMediaProps> = ({ socialMedia }) => {
  return (
    <FooterSocialMediaWrapperStyled>
      <ul>
        {socialMedia.map((socialMediaItem) => {
          const { Id, Link } = socialMediaItem;

          return (
            <li key={Id}>
              <a aria-label={Link.Text} href={Link.Url} target={Link.Target}>
                <Icon height="36px" iconId={Id} width="36px" />
                <span>{Link.Text}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </FooterSocialMediaWrapperStyled>
  );
};

export default SocialMedia;
