/**
 * Only put public interfaces in here
 */

import {Subscribable} from '@volkswagen-onehub/feature-hub-utils';
import React from 'react';

import {
  LayerCloseHandleV1,
  LayerContentElementV1,
  LayerContentOptionsV1,
  LayerHandleV1,
  LayerStyleOptionsV1,
  LayerTypeV1
} from '../v1';

export interface LayerIdOptionsV2 {
  id: string;
}

export type LayerContentOptionsV2 = LayerContentOptionsV1;

export type LayerCloseHandleV2<TReturn> = LayerCloseHandleV1<TReturn>;
export type LayerContentElementV2 = LayerContentElementV1;
export type LayerRenderOptionsV2 = LayerContentOptionsV2 & LayerIdOptionsV2;
export type RenderFunctionV2<TState, TReturn> = (
  state: TState,
  close: LayerCloseHandleV2<TReturn>,
  layerOptions: LayerRenderOptionsV2
) => LayerContentElementV2;

export interface LayerV2<TState, TOptions> {
  readonly handle: LayerHandleV2<TState>;
  readonly isClosed: boolean;
  readonly options: TOptions & LayerStyleOptionsV2;
  readonly id: string;
  element?: HTMLElement;
  close(): void;
  render(): LayerContentElementV2;
}

export type LayerTypeV2 = LayerTypeV1;

interface AlertLayerDescriptionV2<TState> {
  readonly type: 'ALERT';
  readonly layer: LayerV2<TState, AlertLayerOptionsV2>;
}
interface FocusLayerDescriptionV2<TState> {
  readonly type: 'FOCUS';
  readonly layer: LayerV2<TState, FocusLayerOptionsV2>;
}
interface InteractionLayerDescriptionV2<TState> {
  readonly type: 'INTERACTION';
  readonly layer: LayerV2<TState, InteractionLayerOptionsV2>;
}
interface ContentLayerDescriptionV2<TState> {
  readonly type: 'CONTENT';
  readonly layer: LayerV2<TState, ContentLayerOptionsV2>;
}
interface ContentLayerV2DescriptionV2<TState> {
  readonly type: 'CONTENTV2';
  readonly layer: LayerV2<TState, ContentLayerV2OptionsV2>;
}

interface FullscreenLayerDescriptionV2<TState> {
  readonly type: 'FULLSCREEN';
  readonly layer: LayerV2<TState, FullscreenLayerOptionsV2>;
}

interface CustomLayerDescriptionV2<TState> {
  readonly type: 'CUSTOM';
  readonly layer: LayerV2<TState, CustomLayerOptionsV2>;
}

// tslint:disable-next-line:no-any
export type LayerDescriptionV2<TState = any> =
  | AlertLayerDescriptionV2<TState>
  | FocusLayerDescriptionV2<TState>
  | InteractionLayerDescriptionV2<TState>
  | ContentLayerDescriptionV2<TState>
  | ContentLayerV2DescriptionV2<TState>
  | FullscreenLayerDescriptionV2<TState>
  | CustomLayerDescriptionV2<TState>;

export type LayerHandleV2<TState> = LayerHandleV1<TState>;

export type CloseHandleV2<TState, TReturn> = (
  state: TState,
  response: TReturn | undefined,
  layerOptions: LayerContentOptionsV2
) => void;

export interface LayerStyleOptionsV2 extends LayerStyleOptionsV1 {
  /**
   * Disable restoring original focus on layer deactivation
   */
  readonly disableReturnFocus?: boolean;
  /**
   * Close button component that is placed on shim out of the layer
   */
  readonly closeButton?: JSX.Element;
  /**
   * Type of shim background used under the layer. This overrides default shim for layer type
   */
  readonly shimBackgroundType?: ShimBackgroundTypeV2;
}

export interface InternalLayerOptionsV2<TState, TReturn> {
  readonly onClose?: CloseHandleV2<TState, TReturn>;
}

export interface AlertLayerOptionsV2 {}

export const enum FocusLayerSizeV2 {
  A = 'A',
  B = 'B',
  C = 'C',
  NONE = 'NONE'
}

export interface FocusLayerOptionsV2 {
  readonly size: FocusLayerSizeV2;
}

export interface FullscreenLayerOptionsV2 {}

export interface CustomLayerOptionsV2 {
  readonly layerComponent: React.ComponentType<{
    active: boolean;
    scrollElementRef(el: HTMLDivElement): void;
  }>;
  readonly animationClassNames?: string;
  readonly animationTimeout:
    | number
    | {appear?: number; enter?: number; exit?: number};
}

export const enum InteractionLayerSizeV2 {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

export const enum ShimBackgroundTypeV2 {
  OPAQUE = 'OPAQUE',
  TRANSPARENT = 'TRANSPARENT'
}

export interface InteractionLayerOptionsV2 {
  readonly size: InteractionLayerSizeV2;
}

export interface ContentLayerOptionsV2 {}

export interface ContentLayerV2OptionsV2 {
  readonly closeButtonLabel: string;
  readonly contentHeadline: string | JSX.Element;
  readonly disableDefaultPadding?: boolean;
}
export const enum DisclaimerLayerAppearanceV2 {
  DARK = 'DARK',
  LIGHT = 'LIGHT'
}
export interface DisclaimerLayerOptionsV2 {
  readonly appearance?: DisclaimerLayerAppearanceV2;
}

export type LayerOptionsV2<TOptions, TState, TReturn> = InternalLayerOptionsV2<
  TState,
  TReturn
> &
  LayerStyleOptionsV2 &
  TOptions;

type NonOptionalKeys<T> = {
  [k in keyof T]-?: undefined extends T[k] ? never : k;
}[keyof T];

export type OpenFunctionV2<TOptions> = NonOptionalKeys<TOptions> extends never
  ? <TState, TReturn>(
      render: RenderFunctionV2<TState, TReturn>,
      state: TState,
      options?: LayerOptionsV2<TOptions, TState, TReturn>
    ) => LayerHandleV2<TState>
  : <TState, TReturn>(
      render: RenderFunctionV2<TState, TReturn>,
      state: TState,
      options: LayerOptionsV2<TOptions, TState, TReturn>
    ) => LayerHandleV2<TState>;

export interface LayerManagerV2
  extends Omit<
    Subscribable<LayerEventPayload>,
    'eventEmitter' | 'updateInternal'
  > {
  readonly openAlert: OpenFunctionV2<AlertLayerOptionsV2>;
  readonly openFocusLayer: OpenFunctionV2<FocusLayerOptionsV2>;
  readonly openInteractionLayer: OpenFunctionV2<InteractionLayerOptionsV2>;
  /** @deprecated */
  readonly openContentLayer: OpenFunctionV2<ContentLayerOptionsV2>;
  readonly openContentLayerV2: OpenFunctionV2<ContentLayerV2OptionsV2>;
  getLayers(): LayerDescriptionV2[];
  onRender(id: string, el: HTMLElement | null): void;
}

export interface DisclaimerLayerDescriptionV2 {
  readonly type: 'DISCLAIMER';
  readonly layer: LayerV2<unknown, DisclaimerLayerOptionsV2>;
}

export interface LayerManagerV24 extends LayerManagerV2 {
  readonly openDisclaimerLayer: OpenFunctionV2<DisclaimerLayerOptionsV2>;
  getDisclaimerLayer(): DisclaimerLayerDescriptionV2 | undefined;
}

export interface LayerManagerV25 extends LayerManagerV24 {
  readonly openFullscreenLayer: OpenFunctionV2<FullscreenLayerOptionsV2>;
}

export interface LayerManagerV27 extends LayerManagerV25 {
  readonly openCustomLayer: OpenFunctionV2<CustomLayerOptionsV2>;
}

export type LayerEventPayload = 'update' | 'afterRender' | 'beforeClose';
