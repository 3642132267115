/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { App } from '@oneaudi/oneaudi-os-react';
import type { AudiFooterProps } from './components/footer/audi-feature-app-footer';
import AudiFooter from './components/footer/audi-feature-app-footer';

const FeatureApp: React.FC<AudiFooterProps> = ({
  api,
  data,
  countryCode,
  enablePartnerIdReplacement,
  hideSocialMedia,
  error,
  featureAppId,
  referenceServiceManager,
  layerManager,
  marketContext,
  enableMinimalFooter,
  localeService,
  isDemo,
  ...inherited
}) => {
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };

  return (
    <App config={appConfig} app={app} {...inherited}>
      <AudiFooter
        api={api}
        countryCode={countryCode}
        data={data}
        enablePartnerIdReplacement={enablePartnerIdReplacement}
        error={error}
        featureAppId={featureAppId}
        hideSocialMedia={hideSocialMedia}
        referenceServiceManager={referenceServiceManager}
        marketContext={marketContext}
        layerManager={layerManager}
        enableMinimalFooter={enableMinimalFooter}
        localeService={localeService}
        isDemo={isDemo}
      />
    </App>
  );
};

export default FeatureApp;
