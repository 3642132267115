// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fetch from 'node-fetch';

export async function getData(url: string | undefined): Promise<undefined> {
  if (typeof url !== 'undefined') {
    const response = await fetch(url);
    const responseData = await response.json();
    return responseData;
  }
  return undefined;
}
