import React from 'react';
import { ReactComponent as FacebookLogo } from './icons/facebook-medium.svg';
import { ReactComponent as GooglePlusLogo } from './icons/google-plus-medium.svg';
import { ReactComponent as InstagramLogo } from './icons/instagram-medium.svg';
import { ReactComponent as LineLogo } from './icons/line-medium.svg';
import { ReactComponent as LinkedInLogo } from './icons/linkedin-medium.svg';
import { ReactComponent as PinterestLogo } from './icons/pinterest-medium.svg';
import { ReactComponent as SnapchatLogo } from './icons/snapchat-medium.svg';
import { SocialMediaId } from '../../types/audi-footer-response.types';
import { ReactComponent as TwitterLogo } from './icons/twitter-medium.svg';
import { ReactComponent as VimeoLogo } from './icons/vimeo-medium.svg';
import { ReactComponent as VkLogo } from './icons/vk-medium.svg';
import { ReactComponent as WechatLogo } from './icons/wechat-medium.svg';
import { ReactComponent as WeiboLogo } from './icons/weibo-medium.svg';
import { ReactComponent as XingLogo } from './icons/xing-medium.svg';
import { ReactComponent as YouTubeLogo } from './icons/youtube-medium.svg';
import { ReactComponent as ZhihuLogo } from './icons/zhihu-medium.svg';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  iconId: SocialMediaId;
}

const Icon: React.FC<IconProps> = (props) => {
  const { iconId, ...svgAttributes } = props;

  return (
    <>
      {
        {
          facebook: <FacebookLogo {...svgAttributes} />,
          'google-plus': <GooglePlusLogo {...svgAttributes} />,
          instagram: <InstagramLogo {...svgAttributes} />,
          line: <LineLogo {...svgAttributes} />,
          linkedin: <LinkedInLogo {...svgAttributes} />,
          pinterest: <PinterestLogo {...svgAttributes} />,
          snapchat: <SnapchatLogo {...svgAttributes} />,
          twitter: <TwitterLogo {...svgAttributes} />,
          vimeo: <VimeoLogo {...svgAttributes} />,
          vk: <VkLogo {...svgAttributes} />,
          wechat: <WechatLogo {...svgAttributes} />,
          weibo: <WeiboLogo {...svgAttributes} />,
          xing: <XingLogo {...svgAttributes} />,
          youtube: <YouTubeLogo {...svgAttributes} />,
          zhihu: <ZhihuLogo {...svgAttributes} />,
        }[iconId]
      }
    </>
  );
};

export default Icon;
