import {
  AudiFootnoteReferenceServiceInterfaceV3,
  EnumerableFootnote,
} from '@oneaudi/footnote-reference-service';
import { useCallback, useEffect, useState } from 'react';
import { loopOverReferences, replaceTextWithFootnoteNumber, setDataSetonElement } from './helpers';

export const useFootnoteReferenceServiceTextParserHook = ({
  footnotes,
  referenceServiceManager,
  htmlString,
}: {
  footnotes: EnumerableFootnote[];
  referenceServiceManager?: AudiFootnoteReferenceServiceInterfaceV3;
  htmlString: string;
}): string => {
  const replacedHtmlString = htmlString.replace(/(<? *script)/gi, 'illegalscript');
  const [state, setstate] = useState(replacedHtmlString);

  /**
   * This function adds the number for a reference and adds the contextID to the element.
   * It takes the ID of the reference, looks up the number and the contextID in the footnotes array
   * and applies the number to the element and also adds an data-attribute
   */
  const callBackToReplaceHrefinHtmlString = useCallback(
    (footnoteID: string, reference: HTMLLinkElement) => {
      const footnoteReference = footnotes.find((footnote) => footnote.id === footnoteID);
      if (footnoteReference && footnoteReference.number) {
        replaceTextWithFootnoteNumber(reference, footnoteReference.number);
        if (footnoteReference.number > -1) {
          setDataSetonElement({
            element_: reference as HTMLLinkElement,
            id: footnoteReference.contextID ? footnoteReference.contextID : footnoteReference.id,
            name: 'referenceId',
            referenceId: footnoteReference.id,
          });
        }
      }
    },
    [footnotes]
  );

  useEffect(() => {
    if (referenceServiceManager) {
      const referenceIdList: unknown[] = [];
      loopOverReferences(replacedHtmlString, (footnoteID: string) => {
        referenceIdList.push(footnoteID);
      });
      if (referenceIdList.length > 0) {
        referenceServiceManager.addFootnoteReferences(referenceIdList);
      }
    }
  }, [replacedHtmlString, referenceServiceManager]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const htmlStringNew = loopOverReferences(replacedHtmlString, callBackToReplaceHrefinHtmlString);
    setstate(htmlStringNew);
  }, [replacedHtmlString, callBackToReplaceHrefinHtmlString]);

  return state;
};
