import { EventEmitter } from "events";

type SubscribableDefaultPayload = void;

export type Listener<
  TPayload = SubscribableDefaultPayload
> = TPayload extends void ? () => void : (payload: TPayload) => void;

export interface Subscription {
  unsubscribe(): void;
}

export class Subscribable<TPayload = SubscribableDefaultPayload> {
  private readonly eventEmitter = new EventEmitter();

  public subscribe(listener: Listener<TPayload>): Subscription {
    this.eventEmitter.addListener("update", listener);

    return {
      unsubscribe: () => this.eventEmitter.removeListener("update", listener)
    };
  }

  protected updateInternal(payload: TPayload): void {
    this.eventEmitter.emit("update", payload);
  }
}

export class Observable<TValue> extends Subscribable<void> {
  public constructor(public value: TValue) {
    super();
  }

  public update(newValue: TValue): void {
    this.value = newValue;

    this.updateInternal();
  }
}
