import React, { useCallback, useEffect, useState } from 'react';
import {
  AudiFootnoteReferenceServiceInterfaceV3,
  EnumerableFootnote,
} from '@oneaudi/footnote-reference-service';
import { Text, audiDarkTheme, responsiveStyles, Layout } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { FocusLayerSizeV2, LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils';
import { AudiMarketContextServiceV2 } from '@oneaudi/market-context-service';
import { TextProps } from '@audi/audi-ui-react';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { AudiFooterResponse } from '../../types/audi-footer-response.types';
import Categories from '../categories/categories';
import LanguageSwitch from '../language-switch/language-switch';
import LegalLinks from '../legal-links/legal-links';
import { PartnerId } from '../../types/partner-id';
import SocialMedia from '../social-media/social-media';
import ToTopLink from '../to-top-link/to-top-link';
import { getData } from '../../services/api';
import { replacePartnerId } from '../../utils/replace-partner';
import { useFootnoteReferenceServiceTextParserHook } from '../../services/hooks';
import { headless } from '../../utils/set-headless-url';

export type AudiFooterProps = {
  api?: string;
  enablePartnerIdReplacement?: boolean;
  countryCode: string;
  data?: AudiFooterResponse;
  error?: Error;
  hideSocialMedia?: boolean;
  referenceServiceManager?: AudiFootnoteReferenceServiceInterfaceV3;
  layerManager?: LayerManagerV27;
  marketContext?: AudiMarketContextServiceV2;
  featureAppId?: string;
  enableMinimalFooter?: boolean;
  isDemo?: boolean;
  localeService?: LocaleServiceV1;
};

export const breakpointM = audiDarkTheme.breakpoints.m;
// export const breakpointXL = audiDarkTheme.breakpoints.xl;

const OneFooterStyled = styled.div`
  --one-footer-color-black: var(${({ theme }): string => theme.colors.base.brand.black});
  --one-footer-color-white: var(${({ theme }): string => theme.colors.base.brand.white});
  --one-footer-neutral-5: var(${({ theme }): string => theme.colors.base.grey[90]});
  --one-footer-neutral-10: var(${({ theme }): string => theme.colors.base.grey[80]});
  --one-footer-neutral-20: var(${({ theme }): string => theme.colors.base.grey[60]});
  --one-footer-neutral-70: var(${({ theme }): string => theme.colors.base.grey[15]});
  --one-footer-side-spacing: 16px;
  --one-footer-space-xs: var(${({ theme }): string => theme.responsive.spacing.xs});
  --one-footer-space-s: var(${({ theme }): string => theme.responsive.spacing.s});
  --one-footer-space-m: var(${({ theme }): string => theme.responsive.spacing.m});
  --one-footer-space-l: var(${({ theme }): string => theme.responsive.spacing.l});
  --one-footer-space-xl: var(${({ theme }): string => theme.responsive.spacing.xl});
  --one-footer-space-xxl: var(${({ theme }): string => theme.responsive.spacing.xxl});
  --one-footer-space-xxxl: var(${({ theme }): string => theme.responsive.spacing.xxxl});
  --one-footer-side-spacing: 16px;
  background: var(--one-footer-color-black);
  box-sizing: border-box;
  color: var(--one-footer-color-white);
  ${({ theme }): string =>
    responsiveStyles(
      {
        '--one-footer-side-spacing': {
          l: '60px',
          m: '40px',
          s: '28px',
          xl: '96px',
          xxl: 'calc((100% - 1728px) / 2)',
        },
      },
      theme.breakpoints
    )}
  /* stylelint-disable */
    padding: var(--one-footer-space-l) var(--one-footer-side-spacing) var(--one-footer-space-xxl);
  /* stylelint-enable */

  & *,
  & *::after,
  & *::before {
    box-sizing: border-box;
  }

  & a,
  & select,
  & span {
    /* stylelint-disable */
    color: var(--one-footer-color-white);
    cursor: pointer;
    font-family: var(${({ theme }): string => theme.responsive.typography.copy2.fontFamily}),
      sans-serif;
    /* stylelint-enable */
    font-size: var(${({ theme }): string => theme.responsive.typography.copy2.fontSize});
    font-stretch: var(${({ theme }): string => theme.responsive.typography.copy2.fontStretch});
    line-height: var(${({ theme }): string => theme.responsive.typography.copy2.lineHeight});
    text-decoration: none;
  }

  & a:hover,
  & select:hover,
  & a:hover span {
    color: var(--one-footer-neutral-70);
  }

  & a:focus-visible,
  & select:focus-visible {
    outline: var(--one-footer-neutral-20) auto 1px;
  }

  & [data-is-overview-link=\'true\'] {
    ${({ theme }): string =>
      responsiveStyles(
        {
          display: { m: 'none' },
        },
        theme.breakpoints
      )}
  }
`;

const OneFooterStyledToolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const OneFooterStyledToolbarBorderTop = styled.div`
  border-top: 1px solid var(--one-footer-neutral-10);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--one-footer-space-s) 0 0;
  padding: var(--one-footer-space-l) 0 0;
  ${({ theme }): string =>
    responsiveStyles(
      {
        margin: { m: '0' },
        padding: { m: 'var(--one-footer-space-l) 0 0' },
      },
      theme.breakpoints
    )}
`;

const OneFooterStyledMinimalFooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ theme }): string =>
    responsiveStyles(
      {
        margin: { m: '0' },
      },
      theme.breakpoints
    )}
`;

const LegacyLayerStyles = styled.div`
  width: auto;
`;

// add display: inline instead span tag to avoid pointer's cursor
const TextNoCursor = styled(Text)<TextProps>`
  color: var(--one-footer-color-white);
  display: inline;
`;

const LogoImage = styled.img`
  max-width: 48px;
  max-height: 48px;
`;

const AudiFooter: React.FC<AudiFooterProps> = ({
  api,
  localeService,
  countryCode,
  data,
  enablePartnerIdReplacement,
  layerManager,
  error,
  hideSocialMedia,
  marketContext,
  referenceServiceManager,
  featureAppId,
  enableMinimalFooter = false,
  isDemo = false,
}) => {
  const [componentError, setComponentError] = useState<Error | undefined>(error);
  const [componentData, setComponentData] = useState<AudiFooterResponse | undefined>(data);
  const [footnotes, setFootnotes] = useState([]);
  const [partnerId, setPartnerId] = useState<PartnerId>();
  const [useOneLayer, setUseOneLayer] = useState(false);

  const replacedComponentData = componentData
    ? replacePartnerId(componentData, partnerId)
    : undefined;

  const disclaimerText = useFootnoteReferenceServiceTextParserHook({
    footnotes,
    htmlString:
      replacedComponentData && replacedComponentData.Disclaimer
        ? replacedComponentData.Disclaimer
        : '',
    referenceServiceManager,
  });

  useEffect(() => {
    (async (): Promise<void> => {
      if (typeof marketContext !== 'undefined') {
        await marketContext.initMarketContext().then(() => {
          setUseOneLayer(marketContext?.hasEnvScope('FOOTER_ONE_LAYER') || false);
        });
      }
    })();
  }, [marketContext]);

  useEffect(() => {
    if (referenceServiceManager) {
      referenceServiceManager.registerCallback((_footnotes: EnumerableFootnote[]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return setFootnotes(_footnotes);
      });
    }

    return (): void => {
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
  }, [referenceServiceManager]);

  useEffect(() => {
    const abort = new AbortController();
    const locale = `${localeService?.language}/brand/${localeService?.countryCode.toLowerCase()}`;
    const currentLocation = window.location.origin;
    const assembledApiUrl = `${currentLocation}/${locale}/tools/nemo/navigation/onefooter/_jcr_content.json`;
    const demoAPiUrl = `https://fa-nemo-footer.cdn.dev.arcade.apps.one.audi/next/demo/static/audi-footer.json`;
    // Define the API URL based on environment checks
    const apiUrl = DEMO || isDemo ? demoAPiUrl : assembledApiUrl;

    if (typeof componentError === 'undefined' && typeof componentData === 'undefined') {
      const url = api || apiUrl;

      (async () => {
        try {
          const fetchedData = await getData(url);
          setComponentData(fetchedData);
        } catch (getDataError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setComponentError(getDataError);
        }
      })();
    }
    return () => {
      abort.abort();
    };
  }, [api, isDemo, componentError, componentData]);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !enablePartnerIdReplacement ||
      !(window.microkernel && window.microkernel.stateRegistry)
    ) {
      return (): void => {
        // Do nothing
      };
    }

    const partnerCallback = (state: PartnerId): void => {
      setPartnerId(state);
    };

    window.microkernel.stateRegistry.subscribeToStore('dbadDealerStore', partnerCallback);

    return (): void => {
      window.microkernel.stateRegistry.unsubscribeFromStore('dbadDealerStore', partnerCallback);
    };
  }, [enablePartnerIdReplacement, setPartnerId]);

  const SupportLegacyContent = ({ url }: { url: string }) => {
    return (
      <LegacyLayerStyles className="nm-layer nm-layer-visible">
        <LayerContentHTML enableDeepLinking url={`${headless(url)}`} />
      </LegacyLayerStyles>
    );
  };

  const handleLayerClick = useCallback(
    (event): void => {
      const { href } = event.target;
      if (typeof href === 'string' && href !== '') {
        event.preventDefault();
        layerManager?.openFocusLayer(
          () => <SupportLegacyContent url={href} />,
          {},
          {
            size: FocusLayerSizeV2.A,
            userCloseable: true,
          }
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn('[oneaudi-feature-app-nemo-footer]: Please configure a valid layer link');
      }
    },
    [layerManager]
  );

  if (componentError) {
    return <div>Error</div>;
  }

  if (componentData === undefined) {
    return <div>Loading...</div>;
  }

  const filteredLegalLinks = replacedComponentData?.LegalLinks || [];

  return (
    <OneFooterStyled data-fefa-custom-id={featureAppId} data-module="one-footer">
      {enableMinimalFooter ? (
        <>
          {replacedComponentData?.LegalLinks && (
            <OneFooterStyledMinimalFooterContainer>
              <TextNoCursor spaceInlineEnd="xxl" spaceStackEnd="m" variant="copy2">
                {replacedComponentData?.Copyright}
              </TextNoCursor>
              {replacedComponentData?.LegalLinks && (
                <LegalLinks
                  countryCode={countryCode}
                  links={filteredLegalLinks}
                  useOneLayer={useOneLayer}
                  openLayer={handleLayerClick}
                />
              )}
              {replacedComponentData?.LanguageLinks &&
                replacedComponentData?.LanguageLinks.length > 0 && (
                  <LanguageSwitch links={replacedComponentData?.LanguageLinks} />
                )}
            </OneFooterStyledMinimalFooterContainer>
          )}
        </>
      ) : (
        <>
          <OneFooterStyledToolbar>
            <ToTopLink label={replacedComponentData?.ToTopLabel} />
          </OneFooterStyledToolbar>
          <>
            <Categories
              categories={replacedComponentData?.Categories}
              useOneLayer={useOneLayer}
              openLayer={handleLayerClick}
            />
            {!hideSocialMedia &&
              replacedComponentData?.SocialMedia &&
              replacedComponentData?.SocialMedia.length > 0 && (
                <SocialMedia socialMedia={replacedComponentData?.SocialMedia} />
              )}
            <OneFooterStyledToolbarBorderTop>
              <TextNoCursor spaceInlineEnd="xxl" spaceStackEnd="m" variant="copy2">
                {replacedComponentData?.Copyright}
              </TextNoCursor>
              {replacedComponentData?.LegalLinks && (
                <LegalLinks
                  countryCode={countryCode}
                  links={replacedComponentData?.LegalLinks}
                  useOneLayer={useOneLayer}
                  openLayer={handleLayerClick}
                />
              )}
              {replacedComponentData?.LanguageLinks &&
                replacedComponentData?.LanguageLinks.length > 0 && (
                  <LanguageSwitch links={replacedComponentData?.LanguageLinks} />
                )}
            </OneFooterStyledToolbarBorderTop>
            {replacedComponentData &&
              replacedComponentData?.Disclaimer &&
              replacedComponentData?.Disclaimer.length > 0 && (
                <Text as="p" spaceStackStart="l" variant="copy2">
                  <span
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{
                      __html: disclaimerText.replace(
                        /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
                        'illegalscript'
                      ),
                    }}
                  />
                </Text>
              )}
            {/* logo image and image text limited only for the Brazilian market now */}
            {/* remove the localeService conditon if other markets reqiires */}
            {(countryCode === 'BR' && replacedComponentData?.ImageUrl) ||
            (localeService?.countryCode.toLocaleLowerCase() === 'br' &&
              replacedComponentData?.ImageUrl) ? (
              <Layout spaceStackStart="l" align="center" justify="start" columnGap="s">
                <LogoImage
                  src={replacedComponentData.ImageUrl}
                  alt={replacedComponentData?.FooterImageAriaLabel}
                />
                {replacedComponentData?.ImageText && (
                  <TextNoCursor variant="copy2">{replacedComponentData.ImageText}</TextNoCursor>
                )}
              </Layout>
            ) : null}
          </>
        </>
      )}
    </OneFooterStyled>
  );
};

export default AudiFooter;
