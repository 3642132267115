import {Logger} from '@feature-hub/core';
import {
  Listener,
  Subscribable,
  Subscription
} from '@volkswagen-onehub/feature-hub-utils';
import {Layer} from './layer';
import {
  AlertLayerOptionsV2,
  ContentLayerOptionsV2,
  ContentLayerV2OptionsV2,
  CustomLayerOptionsV2,
  DisclaimerLayerDescriptionV2,
  DisclaimerLayerOptionsV2,
  FocusLayerOptionsV2,
  FullscreenLayerOptionsV2,
  InteractionLayerOptionsV2,
  LayerDescriptionV2,
  LayerEventPayload,
  LayerHandleV2,
  LayerManagerV27,
  LayerOptionsV2,
  LayerV2,
  RenderFunctionV2
} from './types';

export * from './types';

export class LayerManagerV2Impl extends Subscribable<LayerEventPayload>
  implements LayerManagerV27 {
  private readonly stack: LayerDescriptionV2[] = [];
  private disclaimer: LayerV2<unknown, DisclaimerLayerOptionsV2> | undefined;

  public constructor(private readonly log: Logger) {
    super();
  }

  public openAlert<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV2<AlertLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    const newLayer = this.createLayer<TState, TReturn, AlertLayerOptionsV2>(
      render,
      state,
      options || {}
    );

    this.addToStack({layer: newLayer, type: 'ALERT'});

    return newLayer.handle;
  }

  public openFocusLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<FocusLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    const newLayer = this.createLayer<TState, TReturn, FocusLayerOptionsV2>(
      render,
      state,
      options
    );

    this.addToStack({layer: newLayer, type: 'FOCUS'});

    return newLayer.handle;
  }

  public openInteractionLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<InteractionLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    const newLayer = this.createLayer<
      TState,
      TReturn,
      InteractionLayerOptionsV2
    >(render, state, options);

    this.addToStack({layer: newLayer, type: 'INTERACTION'});

    return newLayer.handle;
  }

  public openContentLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV2<ContentLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    const newLayer = this.createLayer<TState, TReturn, ContentLayerOptionsV2>(
      render,
      state,
      options || {}
    );

    this.log.warn(
      'The `openContentLayer` function is deprecated. Please use the `openContentLayerV2` function instead. For more details see the following issue in components-core: https://github.com/volkswagen-onehub/components-core/issues/1368'
    );

    this.addToStack({layer: newLayer, type: 'CONTENT'});

    return newLayer.handle;
  }

  public openContentLayerV2<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<ContentLayerV2OptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    const newLayer = this.createLayer<TState, TReturn, ContentLayerV2OptionsV2>(
      render,
      state,
      options
    );

    this.addToStack({layer: newLayer, type: 'CONTENTV2'});

    return newLayer.handle;
  }

  public openFullscreenLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV2<FullscreenLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    const newLayer = this.createLayer<
      TState,
      TReturn,
      FullscreenLayerOptionsV2
    >(render, state, options || {});

    this.addToStack({layer: newLayer, type: 'FULLSCREEN'});

    return newLayer.handle;
  }

  public openCustomLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<CustomLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    const newLayer = this.createLayer<TState, TReturn, CustomLayerOptionsV2>(
      render,
      state,
      options
    );

    this.addToStack({layer: newLayer, type: 'CUSTOM'});

    return newLayer.handle;
  }

  public openDisclaimerLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<DisclaimerLayerOptionsV2, TState, TReturn> = {}
  ): LayerHandleV2<TState> {
    this.closeOpenDisclaimerLayer();

    this.disclaimer = new Layer(
      render,
      state,
      {},
      options,
      () => {
        this.updateInternal('beforeClose');

        if (this.disclaimer) {
          this.disclaimer = undefined;

          return true;
        }

        return false;
      },
      () => this.updateInternal('update')
    );

    this.updateInternal('update');

    return this.disclaimer.handle;
  }

  public getDisclaimerLayer(): DisclaimerLayerDescriptionV2 | undefined {
    return this.disclaimer
      ? {type: 'DISCLAIMER', layer: this.disclaimer}
      : undefined;
  }

  public onRender(id: string, el: HTMLElement | null): void {
    const layers = this.getLayers().filter(layer => layer.layer.id === id);
    if (el != null && layers.length === 1) {
      layers[0].layer.element = el;
      this.updateInternal('afterRender');
    }
  }

  public getLayers(): LayerDescriptionV2[] {
    const {stack} = this;

    let alertFound = false;
    const layers: LayerDescriptionV2[] = stack
      .slice() // must clone to not modify original array
      .reverse()
      .filter(layer => {
        if (layer.type === 'ALERT') {
          if (alertFound) {
            return false;
          }
          alertFound = true;
        }

        return true;
      })
      .reverse();

    return layers;
  }

  private closeStackableLayer(layer: LayerV2<unknown, unknown>): boolean {
    const layerIndex = this.stack.findIndex(current => current.layer === layer);

    if (layerIndex === -1) {
      return false;
    }

    this.stack.splice(layerIndex, 1);

    return true;
  }

  private addToStack(layerDescription: LayerDescriptionV2): void {
    this.stack.push(layerDescription);
    this.updateInternal('update');
  }

  private createLayer<TState, TReturn, LayerOptions>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<LayerOptions, TState, TReturn>
  ): LayerV2<TState, LayerOptions> {
    if (!render) {
      throw new Error('no render function was provided');
    }

    this.closeOpenDisclaimerLayer();

    return new Layer(
      render,
      state,
      {},
      options,
      (currentLayer: LayerV2<TState, LayerOptions>) => {
        this.updateInternal('beforeClose');

        return this.closeStackableLayer(currentLayer);
      },
      () => this.updateInternal('update')
    );
  }

  private closeOpenDisclaimerLayer(): void {
    if (this.disclaimer && !this.disclaimer.isClosed) {
      this.disclaimer.close();
      this.disclaimer = undefined;
    }
  }
}

export class LayerManagerV2ImplProxy implements LayerManagerV27 {
  private readonly openLayers: Set<LayerHandleV2<unknown>> = new Set();
  private readonly subscriptions: Set<Subscription> = new Set();

  public constructor(private readonly layerManager: LayerManagerV27) {}

  public unbind(): void {
    this.openLayers.forEach(handle => {
      if (!handle.isClosed()) {
        handle.close();
      }
    });
    this.openLayers.clear();

    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions.clear();
  }

  public openAlert<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV2<AlertLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      this.layerManager.openAlert(render, state, options)
    );
  }

  public openFocusLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<FocusLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      this.layerManager.openFocusLayer(render, state, options)
    );
  }

  public openFullscreenLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV2<FullscreenLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      this.layerManager.openFullscreenLayer(render, state, options)
    );
  }

  public openCustomLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<CustomLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      this.layerManager.openCustomLayer(render, state, options)
    );
  }

  public openInteractionLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<InteractionLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      this.layerManager.openInteractionLayer(render, state, options)
    );
  }

  public openContentLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options?: LayerOptionsV2<ContentLayerOptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      // tslint:disable-next-line: deprecation
      this.layerManager.openContentLayer(render, state, options)
    );
  }

  public openContentLayerV2<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<ContentLayerV2OptionsV2, TState, TReturn>
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      this.layerManager.openContentLayerV2(render, state, options)
    );
  }

  public openDisclaimerLayer<TState, TReturn>(
    render: RenderFunctionV2<TState, TReturn>,
    state: TState,
    options: LayerOptionsV2<DisclaimerLayerOptionsV2, TState, TReturn> = {}
  ): LayerHandleV2<TState> {
    return this.createProxiedCloseHandle(
      this.layerManager.openDisclaimerLayer(render, state, options)
    );
  }

  public onRender(id: string, el: HTMLElement | null): void {
    this.layerManager.onRender(id, el);
  }

  public getLayers(): LayerDescriptionV2[] {
    return this.layerManager.getLayers();
  }

  public getDisclaimerLayer(): DisclaimerLayerDescriptionV2 | undefined {
    return this.layerManager.getDisclaimerLayer();
  }

  public subscribe(listener: Listener<LayerEventPayload>): Subscription {
    const subscription = this.layerManager.subscribe(listener);
    this.subscriptions.add(subscription);

    return {
      unsubscribe: () => {
        this.subscriptions.delete(subscription);
        subscription.unsubscribe();
      }
    };
  }

  private createProxiedCloseHandle<TState>(
    handle: LayerHandleV2<TState>
  ): LayerHandleV2<TState> {
    const proxyClose = () => {
      this.openLayers.delete(handle);
      handle.close();
    };
    this.openLayers.add(handle);

    return {
      ...handle,
      close: proxyClose
    };
  }
}
